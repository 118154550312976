
import { defineComponent } from 'vue'
import { ref } from 'vue'
import { Plus } from '@element-plus/icons'

import { useNotification } from '@/composables'
import { validateImageFileUploaded } from '@/utils/file'
import UploadImageDialog from '@/components/common/UploadImageDialog.vue'

export default defineComponent({
  components: {
    Plus,
    UploadImageDialog,
  },
  props: {
    itemIndex: {
      type: Number,
      required: true,
    },
    artworkName: {
      type: String,
      required: true,
    },
    disableEdit: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const uploadedUrl = ref('')

    const disableEditStatus = ref(props.disableEdit)

    const { warningNoti } = useNotification()
    const onClickUpload = () => {
      if (disableEditStatus.value) {
        warningNoti('You cannot edit the order information.')
        return
      }
      onClickUploadImageDialog()
    }

    const { error } = useNotification()
    const uploadDesign = async () => {
      try {
        emit('uploaded', {
          path: uploadedUrl.value,
          artwork: props.artworkName,
          index: props.itemIndex,
        })
      } catch (err: any) {
        error(err.message)
      }
    }

    const onAfterUploadFile = async (data: any) => {
      uploadedUrl.value = data.path
      uploadDesign()
    }

    const uploadImageDialog = ref<InstanceType<typeof UploadImageDialog>>()
    const onClickUploadImageDialog = () => {
      uploadImageDialog.value?.toggle()
    }

    return {
      onClickUpload,
      uploadDesign,
      disableEditStatus,
      validateImageFileUploaded,
      onClickUploadImageDialog,
      uploadImageDialog,
      onAfterUploadFile,
      uploadedUrl,
    }
  },
})
