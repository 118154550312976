
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { CopyDocument } from '@element-plus/icons'
import { useRoute, useRouter } from 'vue-router'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { imageUrl } from '@/utils/image'
import UploadDesign from './components/UploadDesign.vue'
import CustomDialog from '@/components/orders/CustomDialog.vue'
import { useNotification } from '@/composables'
import { formatDateTime, formatTime } from '@/utils/dateTime'
import { formatterDollars } from '@/utils/formatter'
import { copyToClipboard } from '@/utils/string'
import {
  DESIGN_STATUS,
  ADDRESS_STATUS,
  ORDER_STATUS,
  HIRE_DESIGN_STATUS,
  statusTitleMap,
  designServiceTitleMap,
} from '@/utils/constants'
import UploadImage from './components/UploadImage.vue'
import {
  getOrderStatusColor,
  getAddressStatusColor,
  countDesignQtyOrderItem,
  validateOrderAction,
  changeHistory,
  changeMetadata,
  CHANGE_ORDER_HISTORY,
  isDesignerManager,
  isDesignerAssignment,
} from '@/utils/orderUtils'
import { addressTitleMap } from '@/utils/constants'
import {
  validateEditArtworkCheck,
  validateEditableByDesignStatus,
} from '@/utils/orderUtils'
import { UserTypes } from '@/utils/types'
import { AuthorityEnum } from '@/utils/userUtils'
import HireDesignStatusTag from '@/components/orders/display/hire-design/HireDesignStatusTag.vue'
import ChangeHistoryContent from '@/components/orders/ChangeHistoryContent.vue'

export default defineComponent({
  components: {
    sectionLayoutContent,
    UploadDesign,
    UploadImage,
    CopyDocument,
    CustomDialog,
    HireDesignStatusTag,
    ChangeHistoryContent,
  },
  setup() {
    const breadcrumbs = [
      {
        text: `Orders`,
        link: '',
        params: null,
      },
      {
        text: `Detail`,
        link: '',
        params: null,
      },
    ]
    const route = useRoute()
    const router = useRouter()
    const data = reactive<{
      rules: any
      isLoading: boolean
      variant: any
      showRejectModal: boolean
      showEditCostModal: boolean
      rejectItem: any[]
      designFeeValue: number
    }>({
      rules: {
        fullName: [
          {
            required: true,
            message: 'Please input Name',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 255,
            message: 'Length should be 3 to 255',
            trigger: 'blur',
          },
        ],
        address1: [
          {
            required: true,
            message: 'Please input Address',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 255,
            message: 'Length should be 3 to 255',
            trigger: 'blur',
          },
        ],
        city: [
          {
            required: true,
            message: 'Please input City',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 255,
            message: 'Length should be 3 to 255',
            trigger: 'blur',
          },
        ],
        zipCode: [
          {
            required: true,
            message: 'Please input Zipcode',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 60,
            message: 'Length should be 3 to 255',
            trigger: 'blur',
          },
        ],
      },
      isLoading: true,
      variant: {
        variantTitle: '',
        artwork1: '',
        artwork2: '',
        artwork3: '',
        artwork4: '',
      },
      showRejectModal: false,
      showEditCostModal: false,
      rejectItem: [],
      designFeeValue: 0,
    })

    const orderCannotUpdateMsg = 'This order can not update.'

    const {
      getOrder,
      state,
      editOrder,
      markAddressValid,
      checkAddressAgain,
      calcOrderProfit,
    } = useOrderRepositories()
    const artworkSpaces = ref(['artwork1', 'artwork2', 'artwork3', 'artwork4'])
    const content = computed(() => {
      return { breadcrumbs }
    })

    const onShowRejectModal = () => {
      data.showRejectModal = true
    }
    const onShowDesignServiceCostModal = () => {
      data.showEditCostModal = true
    }

    const { error, success } = useNotification()

    const validateDesignQtyOrderItem = (item: any) => {
      if (item) {
        const designQty = item.designQty ? item.designQty : 0
        const countDesignOfItem = countDesignQtyOrderItem(item)
        return designQty === countDesignOfItem
      }
    }

    const {
      validateHold,
      validateUnHold,
      validateSellerCancel,
      validateVerifyAddress,
      validateOrderActionSeller,
      ALLOW_STATUS_WITH_ACTION,
      validateUpdateSyncedTrackToStore,
    } = validateOrderAction()

    const {
      cancelOrders,
      verifyAddressOrders,
      holdOrders,
      unHoldOrders,
      syncedTrackToStore,
      changeDesignStatusOrder,
    } = useOrderRepositories()

    const { ROLE, ACTION_NAME } = CHANGE_ORDER_HISTORY()

    const userInfo = JSON.parse(localStorage?.getItem('info') || '')

    const validOrderBeforeUpdate = async () => {
      await fetchRecord()
      const currentOrderStatus = state.order.status
      return (
        currentOrderStatus &&
        (currentOrderStatus === ORDER_STATUS.UNFULFILLED ||
          currentOrderStatus === ORDER_STATUS.IN_REVIEW ||
          currentOrderStatus === ORDER_STATUS.HOLD ||
          currentOrderStatus === ORDER_STATUS.IN_PRODUCTION ||
          currentOrderStatus === ORDER_STATUS.IN_TRANSIT)
      )
    }

    // hàm được gọi sau khi upload thêm design của item lên
    const uploaded = async (uploaded: any) => {
      console.log('uploaded')
      const isValidOrderForUpdate = await validOrderBeforeUpdate()
      if (!isValidOrderForUpdate) {
        error(orderCannotUpdateMsg)
        return
      }

      // phuc vu ghi log
      const itemIndex = uploaded.index
      const oldDesignPath = state.order.items[uploaded.index][uploaded.artwork]
      const newDesignPath = imageUrl(uploaded.path)
      const dataChange = {
        actionName: ACTION_NAME.UPLOAD_DESIGN,
        itemId: state.order.items[itemIndex]?._id,
        log: {
          designName: uploaded.artwork,
          itemIndex: itemIndex + 1,
          oldDesignPath: oldDesignPath,
          newDesignPath: newDesignPath,
        },
        createdDate: new Date(),
        seller: userInfo
          ? {
              id: userInfo.id,
              agency: userInfo.agency,
              email: userInfo.email,
              sellerTeam: {
                id: userInfo?.sellerTeam?.id,
              },
            }
          : {},
      }
      state.order = changeHistory(state.order, ROLE.SELLER, dataChange)

      state.order.items[uploaded.index][uploaded.artwork] = imageUrl(
        uploaded.path
      )
      // check số lượng design của item sau khi upload thêm desgin lên
      const checkDesignQty = validateDesignQtyOrderItem(
        state.order.items[uploaded.index]
      )
      state.order.items[uploaded.index].designStatus = checkDesignQty
        ? DESIGN_STATUS.MAPPED_UPLOADED
        : DESIGN_STATUS.NONE

      // lưu thông tin order
      const { status } = await editOrder(state.order._id, state.order)
      if (status === 200) {
        success('Success')
        fetchRecord()
      } else {
        error('Failed')
      }
    }

    const copyToClipboardOrderName = (text: string) => {
      copyToClipboard(text)
      success('Copy to clipboard')
    }

    const fetchRecord = async () => {
      data.isLoading = true
      let order = await getOrder(route.params.id)
      data.designFeeValue = state.order.designFeeValue
      data.isLoading = false
    }

    onMounted(async () => {
      fetchRecord()
    })

    // hàm được gọi sau khi upload change design của item lên
    const changeArtwork = async (uploaded: any) => {
      console.log('changeArtwork')
      const isValidOrderForUpdate = await validOrderBeforeUpdate()
      if (!isValidOrderForUpdate) {
        error(orderCannotUpdateMsg)
        return
      }

      // phuc vu ghi log
      const itemIndex = uploaded.index
      const oldDesignPath = state.order.items[uploaded.index][uploaded.artwork]
      const newDesignPath = imageUrl(uploaded.path)
      const dataChange = {
        actionName: ACTION_NAME.CHANGE_DESIGN,
        itemId: state.order.items[itemIndex]?._id,
        log: {
          designName: uploaded.artwork,
          itemIndex: itemIndex + 1,
          oldDesignPath: oldDesignPath,
          newDesignPath: newDesignPath,
        },
        createdDate: new Date(),
        seller: userInfo
          ? {
              id: userInfo.id,
              agency: userInfo.agency,
              email: userInfo.email,
              sellerTeam: {
                id: userInfo?.sellerTeam?.id,
              },
            }
          : {},
      }
      state.order = changeHistory(state.order, ROLE.SELLER, dataChange)

      state.order.items[uploaded.index][uploaded.artwork] = imageUrl(
        uploaded.path
      )
      const { status } = await editOrder(state.order._id, state.order)
      if (status === 200) {
        success('Success')
        fetchRecord()
      } else {
        error('Failed')
      }
    }

    const totalItems = computed(() => {
      if (state.order && state.order.items) {
        const total = state.order.items.reduce((t: number, item: any) => {
          return t + item.quantity
        }, 0)
        return total
      }
      return 0
    })

    const { params } = useRoute()
    const validAddress = async () => {
      state.order.addressValid = 2
      const { data, status } = await markAddressValid(params.id as string)
      if (status === 200 || status === 201) {
        success('Success')
      } else {
        error(data.message)
      }
    }

    const callbackImageSrc = async () => {
      console.log('callbackImageSrc')
    }

    const changeImageSrc = async (uploaded: any) => {
      console.log('changeImageSrc')
      const isValidOrderForUpdate = await validOrderBeforeUpdate()
      if (!isValidOrderForUpdate) {
        error(orderCannotUpdateMsg)
        return
      }

      const index = uploaded.index
      const path = uploaded.path
      const artwork = uploaded.artwork
      if (artwork === 'imageSrc' && path?.length > 0) {
        const oldPreviewPath = state.order.items[index][artwork]
        // Object.assign(state.order.items[index], { imageSrc: path })
        state.order.items[index][artwork] = imageUrl(path)

        // phuc vu ghi log
        const dataChange = {
          actionName: ACTION_NAME.CHANGE_PREVIEW,
          itemId: state.order.items[index]._id,
          log: {
            itemIndex: index + 1,
            oldPreviewPath: oldPreviewPath,
            newPreviewPath: imageUrl(path),
          },
          createdDate: new Date(),
          seller: userInfo
            ? {
                id: userInfo.id,
                agency: userInfo.agency,
                email: userInfo.email,
                sellerTeam: {
                  id: userInfo?.sellerTeam?.id,
                },
              }
            : {},
        }
        state.order = changeHistory(state.order, ROLE.SELLER, dataChange)

        const { status } = await editOrder(state.order._id, state.order)
        if (status === 200) {
          success(`Update preview success!`)
          fetchRecord()
        } else {
          error(`Update preview fail`)
        }
      } else {
        error('Error when update preview!')
      }
    }

    const callbackDesign = async (
      setData: any,
      files: string[],
      data: any,
      action: any,
      artwork: any,
      itemIndex: any
    ) => {
      console.log('callbackDesign')
      const isValidOrderForUpdate = await validOrderBeforeUpdate()
      if (!isValidOrderForUpdate) {
        error(orderCannotUpdateMsg)
        return
      }

      let message = ''
      if (action === 'del') {
        state.order.items[itemIndex][artwork] = ''
        message = 'Deleted artwork'
      } else if (action === 'add') {
        state.order.items[itemIndex][artwork] = imageUrl(files[0])
        message = 'Uploaded artwork'
      }

      // check số lượng design của item sau khi upload thêm desgin lên
      const checkDesignQty = validateDesignQtyOrderItem(
        state.order.items[itemIndex]
      )
      state.order.items[itemIndex].designStatus = checkDesignQty
        ? DESIGN_STATUS.MAPPED_UPLOADED
        : DESIGN_STATUS.NONE

      const { status } = await editOrder(state.order._id, state.order)
      if (status === 200) {
        success(`${message} success`)
        fetchRecord()
      } else {
        error(`${message} fail`)
      }
    }

    const onAfterChangeVariantCode = async (data: any) => {
      const newCatalogCode = data.variantCode
      const designQty = data.designQty
      const itemId = data.itemId

      const isValidOrderForUpdate = await validOrderBeforeUpdate()
      if (!isValidOrderForUpdate) {
        error(orderCannotUpdateMsg)
        return
      }

      if (newCatalogCode?.length === 0) {
        error('Not found new catalog code!')
        return
      }
      if (itemId?.length === 0) {
        error('itemId is null!')
        return
      }
      if (state.order.status !== ORDER_STATUS.UNFULFILLED) {
        error('Can not update this order!')
        return
      }
      const itemNeedUpdateIndex = state.order.items.findIndex(
        (item: { _id: any }) => item._id === itemId
      ) as any
      if (itemNeedUpdateIndex !== -1) {
        // phuc vu ghi log
        const oldCatalogCode =
          state.order.items[itemNeedUpdateIndex].catalogCode
        const oldDesignQty = state.order.items[itemNeedUpdateIndex].designQty

        state.order.items[itemNeedUpdateIndex].catalogCode = newCatalogCode
        state.order.items[itemNeedUpdateIndex].designQty = designQty

        const designStatus = validateDesignQtyOrderItem(
          state.order.items[itemNeedUpdateIndex]
        )
          ? DESIGN_STATUS.MAPPED_UPLOADED
          : DESIGN_STATUS.NONE

        state.order.items[itemNeedUpdateIndex].designStatus = designStatus
        console.log(userInfo)

        // phuc vu ghi log
        const dataChange = {
          actionName: ACTION_NAME.CHANGE_CATALOG_CODE,
          itemId: state.order.items[itemNeedUpdateIndex]._id,
          log: {
            oldCatalogCode: oldCatalogCode,
            oldDesignQty: oldDesignQty,
            newCatalogCode: newCatalogCode,
            newDesignQty: designQty,
            newDesignStatus: designStatus,
          },
          createdDate: new Date(),
          seller: userInfo
            ? {
                id: userInfo.id,
                agency: userInfo.agency,
                email: userInfo.email,
                sellerTeam: {
                  id: userInfo?.sellerTeam?.id,
                },
              }
            : {},
        }
        state.order = changeHistory(state.order, ROLE.SELLER, dataChange)

        const { status } = await editOrder(state.order._id, state.order)
        if (status === 200 || status === 201) {
          success(`Change catalog code success!`)
          fetchRecord()
        } else {
          error(`Change catalog code fail`)
        }
      }
    }

    const customDialog = ref<InstanceType<typeof CustomDialog>>()
    const onViewDetailCustom = (custom: any) => {
      customDialog.value?.toggle(custom)
    }

    const showErrorMsg = (errorMsg: string) => {
      if (errorMsg) {
        return errorMsg.replaceAll('\n', '<br>')
      }
    }

    const handleCheckedItemsChange = async (val: any) => {
      data.rejectItem = val
    }

    const onChangeDesignCost = async () => {
      try {
        const dataChange = {
          actionName: ACTION_NAME.CHANGE_DESIGN_COST,
          //itemId: state.order.items[itemIndex]?._id,
          log: {
            oldCost: data.designFeeValue,
            newCost: state.order.designFeeValue,
          },
          createdDate: new Date(),
          userLog: userInfo
            ? {
                id: userInfo.id,
                agency: userInfo.agency,
                email: userInfo.email,
                userRole: userInfo.userType,
              }
            : {},
        }

        console.log('state.dataChange', dataChange)

        state.order = changeHistory(
          state.order,
          userInfo
            ? userInfo.userType === UserTypes.AGENCY
              ? ROLE.AGENCY
              : userInfo.userType === ROLE.DESIGNER
              ? ROLE.DESIGNER
              : ''
            : '',
          dataChange
        )

        const metadata = {
          designFeeValue: state.order.designFeeValue,
        }

        state.order = changeMetadata(state.order, metadata)

        // lưu thông tin order
        const { status } = await editOrder(state.order._id, state.order)
        if (status === 200) {
          success('Success')
          data.showEditCostModal = false
          fetchRecord()

          // update profit
          if (state.order?.syncProfit === 1 || state.order?.syncProfit === 2) {
            await calcOrderProfit(state.order?._id?.toString())
          }
        } else {
          error('Failed')
        }
      } catch (err) {
        error()
      }
    }

    const onChangeRequestDesignStatus = async (designStatus: string) => {
      try {
        const dataChange = {
          actionName: ACTION_NAME.CHANGE_DESIGN_SERVICE_STATUS,
          //itemId: state.order.items[itemIndex]?._id,
          log: {
            oldStatus: state.order.metadata.hire_design_status,
            newStatus: designStatus,
          },
          createdDate: new Date(),
          userLog: userInfo
            ? {
                id: userInfo.id,
                agency: userInfo.agency,
                email: userInfo.email,
                userRole: userInfo.userType,
              }
            : {},
        }

        state.order = changeHistory(
          state.order,
          userInfo
            ? userInfo.userType === UserTypes.AGENCY
              ? ROLE.AGENCY
              : userInfo.userType === ROLE.DESIGNER
              ? ROLE.DESIGNER
              : ''
            : '',
          dataChange
        )

        const metadata = {
          hire_design_status: designStatus,
          items_issues: data.rejectItem
            ? (data.rejectItem as any[]).map(item => {
                return {
                  id: item._id,
                  imageSrc: item.imageSrc,
                  itemName: item.itemName,
                }
              })
            : null,
        }

        console.log('item_issue', metadata)

        state.order = changeMetadata(state.order, metadata)

        // lưu thông tin order
        const { status } = await editOrder(state.order._id, state.order)
        if (status === 200) {
          success('Success')
          data.showRejectModal = false
          fetchRecord()
        } else {
          error('Failed')
        }

        // let updateParams = {
        //   id: route.params.id,
        //   design_status: designStatus,
        //   approve_note: state.order.metadata.notes,
        //   itemIds: data.rejectItem.map((item: any) => item._id),
        //   designFeeValue: data.designFeeValue,
        // }
        // const res = await changeDesignStatusOrder(updateParams)
        // if (res?.status === 200 || res?.status === 201) {
        //   success(res?.data?.message || `Update design info success!`)
        //   fetchRecord()
        //   data.showEditCostModal = false
        //   data.showRejectModal = false
        // } else {
        //   error(res?.data?.message)
        // }
      } catch (err) {
        error()
      }
    }

    const unHoldOrder = async (ids: any[]) => {
      try {
        const res = (await unHoldOrders({
          ids: ids,
        })) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Unhold order success!`)
          fetchRecord()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const onHireDesignCheckedChange = async () => {
      try {
        if (onCheckHasEnoughDesign()) {
          let updateParams = {
            id: route.params.id,
            design_status: HIRE_DESIGN_STATUS.INREVIEW,
          }

          const res = await changeDesignStatusOrder(updateParams)
          if (res?.status === 200 || res?.status === 201) {
            success(res?.data?.message || `Update design info success!`)
            fetchRecord()
          } else {
            error(res?.data?.message)
          }
        }
      } catch (err) {
        error()
      }
    }

    const onCheckHasEnoughDesign = () => {
      const items = state.order.items.filter(
        (item: any) =>
          item.itemName !== 'tip' &&
          !item.artwork1 &&
          !item.artwork2 &&
          !item.artwork3 &&
          !item.artwork4
      )
      if (items && items.length > 0) {
        console.log('items->', items)

        error('Not enough designs!')
        return false
      }
      return true
    }

    const cancelOrder = async (ids: any[]) => {
      try {
        const res = (await cancelOrders({
          ids: ids,
        })) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Cancel order success!`)
          fetchRecord()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const verifyAddressOrder = async (ids: any[]) => {
      try {
        const res = (await verifyAddressOrders({
          ids: ids,
        })) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Verify Address order success!`)
          fetchRecord()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const syncedTrackToStoreOrder = async (ids: any[]) => {
      try {
        const res = (await syncedTrackToStore(ids)) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Update order success!`)
          fetchRecord()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const isManager = ref<boolean>(isDesignerManager(userInfo))

    const isDesigner = ref<boolean>(isDesignerAssignment(userInfo))

    const isEditableDesignByDesigner = (
      orderStatus: ORDER_STATUS,
      assignStatus: HIRE_DESIGN_STATUS
    ) => {
      const editableByOrderStatus = validateEditArtworkCheck(orderStatus)
      const editableByAssignStatus =
        validateEditableByDesignStatus(assignStatus)
      return editableByOrderStatus && editableByAssignStatus
    }

    return {
      isEditableDesignByDesigner,
      onViewDetailCustom,
      onAfterChangeVariantCode,
      content,
      callbackDesign,
      state,
      totalItems,
      data,
      getOrder,
      uploaded,
      imageUrl,
      fetchRecord,
      validAddress,
      artworkSpaces,
      statusTitleMap,
      designServiceTitleMap,
      changeDesignStatusOrder,
      formatDateTime,
      onCheckHasEnoughDesign,
      formatTime,
      formatterDollars,
      copyToClipboard,
      onHireDesignCheckedChange,
      onChangeRequestDesignStatus,
      onChangeDesignCost,
      handleCheckedItemsChange,
      onShowRejectModal,
      onShowDesignServiceCostModal,
      copyToClipboardOrderName,
      getOrderStatusColor,
      getAddressStatusColor,
      addressTitleMap,
      validateEditArtworkCheck,
      changeArtwork,
      customDialog,
      validateDesignQtyOrderItem,
      DESIGN_STATUS,
      countDesignQtyOrderItem,
      router,
      callbackImageSrc,
      changeImageSrc,
      showErrorMsg,
      validateOrderAction,
      validateOrderActionSeller,
      validateHold,
      validateUnHold,
      validateSellerCancel,
      validateVerifyAddress,
      ALLOW_STATUS_WITH_ACTION,
      validateUpdateSyncedTrackToStore,
      cancelOrders,
      verifyAddressOrders,
      unHoldOrders,
      syncedTrackToStore,
      unHoldOrder,
      cancelOrder,
      verifyAddressOrder,
      syncedTrackToStoreOrder,
      UserTypes,
      userInfo,
      ADDRESS_STATUS,
      ORDER_STATUS,
      HIRE_DESIGN_STATUS,
      changeHistory,
      changeMetadata,
      validOrderBeforeUpdate,
      orderCannotUpdateMsg,
      AuthorityEnum,
      isManager,
      validateEditableByDesignStatus,
      isDesigner,
    }
  },
})
