
import { ref, defineComponent } from 'vue'
import { useNotification } from '@/composables'

export default defineComponent({
  components: {},
  setup() {
    const dialogTableVisible = ref(false)
    const activeName = ref('table')
    const isLoading = ref(true)
    const { error, success } = useNotification()
    const customs = ref()

    const toggle = (customData: any) => {
      if (!customData) {
        error('Not found data!')
      }
      customs.value = customData
      dialogTableVisible.value = true
      isLoading.value = false
    }

    return {
      dialogTableVisible,
      toggle,
      customs,
      isLoading,
      activeName,
    }
  },
})
