
import {
  Plus,
  View,
  Delete,
  CopyDocument,
  Edit,
  Refresh,
  UploadFilled,
} from '@element-plus/icons'
import useUploadRepositories, {
  FileItem,
} from '@/repositories/useUploadRepositories'
import { imageUrl } from '@/utils/image'
import { ref, defineComponent } from 'vue'
import { getFileNameFromUrl } from '@/utils/file'
import { copyToClipboard } from '@/utils/string'
import { useNotification } from '@/composables'
import { ElMessage, ElMessageBox } from 'element-plus'
import { validateImageFileUploaded } from '@/utils/file'
import PreviewImage from '@/components/common/PreviewImage.vue'
import SlotError from '@/components/common/SlotError.vue'

export default defineComponent({
  components: {
    Plus,
    View,
    Delete,
    CopyDocument,
    Edit,
    Refresh,
    PreviewImage,
    UploadFilled,
    SlotError,
  },
  props: {
    setData: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
      default: '',
    },
    permission: {
      type: String,
      default: 'all',
    },
    artwork: {
      type: String,
      default: '',
    },
    data: {
      type: String,
      default: 'src',
    },
    accept: {
      type: String,
      default: '*',
    },
    itemIndex: {
      type: Number,
      required: true,
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { onUploadImage } = useUploadRepositories()
    const files = ref<any>([])
    files.value.push(props.data)
    const inputFile = ref<HTMLInputElement | null>(null)
    const upload = () => {
      inputFile.value?.click()
    }
    const views = ref<any>(props.setData[props.data])
    const onChangeFiles = async (e: any) => {
      const file = e.target.files[0]
      const res = (await onUploadImage(file)) as FileItem & {
        status: 500
        error: any
      }
      files.value = []
      files.value.push(res.path)
      emit(
        'callback',
        props.setData,
        files.value,
        props.data,
        'add',
        props.artwork,
        props.itemIndex
      )
    }
    const { success, error, warning } = useNotification()

    const copyToClipboardUrl = (text: string) => {
      copyToClipboard(text)
      success('Copy to clipboard')
    }

    const dialog = ref<boolean>(false)
    const imgView = ref<string>('')
    const view = (path: string) => {
      dialog.value = true
      imgView.value = path
    }

    const activeTabName = ref('uploadFile')
    const dialogChange = ref<boolean>(false)
    const inputFileUrl = ref<string>('')
    const change = (path: string) => {
      dialogChange.value = true
      inputFileUrl.value = imageUrl(path)
      uploadedFileUrl.value = imageUrl(path, true, 300)
    }

    const uploadedFileUrl = ref<string>('')
    const uploadedFile = ref<any>(null)

    const onUploadDesign = (file: any) => {
      if (file && validateImageFileUploaded(file)) {
        uploadedFile.value = file
        uploadedFileUrl.value = window.URL.createObjectURL(file.raw)
      }
    }

    const onClearUploadedFile = () => {
      uploadedFileUrl.value = ''
      uploadedFile.value = null
    }

    const previewImageDialog = ref<InstanceType<typeof PreviewImage>>()
    const onClickPreviewImage = () => {
      previewImageDialog.value?.toggle(uploadedFileUrl.value)
    }

    const onSaveChange = async () => {
      if (activeTabName.value === 'uploadFile' && uploadedFile.value) {
        const res = await onUploadImage(uploadedFile.value.raw)
        emit('changeArtwork', {
          path: res?.path,
          artwork: props.artwork,
          index: props.itemIndex,
        })
      } else {
        emit('changeArtwork', {
          path: inputFileUrl.value,
          artwork: props.artwork,
          index: props.itemIndex,
        })
      }
      dialogChange.value = false
    }

    const confirmDelete = (index: number) => {
      ElMessageBox.confirm(
        'System will delete the artwork. Continue?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(() => {
          delImg(index)
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: 'Delete canceled',
          })
        })
    }

    const delImg = (index: number) => {
      files.value.splice(index, 1)
      emit(
        'callback',
        props.setData,
        files.value,
        props.data,
        'del',
        props.artwork,
        props.itemIndex
      )
    }
    const hoverIndex = ref(-1)
    const onMouse = (index: number) => {
      hoverIndex.value = index
    }
    const onMouseMove = () => {
      hoverIndex.value = -1
    }
    return {
      onClickPreviewImage,
      previewImageDialog,
      onUploadImage,
      onChangeFiles,
      files,
      upload,
      inputFile,
      view,
      dialog,
      imgView,
      delImg,
      onMouse,
      hoverIndex,
      views,
      onMouseMove,
      imageUrl,
      getFileNameFromUrl,
      copyToClipboardUrl,
      confirmDelete,
      change,
      dialogChange,
      activeTabName,
      onUploadDesign,
      uploadedFileUrl,
      uploadedFile,
      onSaveChange,
      onClearUploadedFile,
      inputFileUrl,
      validateImageFileUploaded,
    }
  },
})
