
import { defineComponent, ref } from 'vue'
import { UploadFilled, Refresh, View } from '@element-plus/icons'
import { validateImageFileUploaded } from '@/utils/file'
import { useNotification } from '@/composables'
import PreviewImage from '@/components/common/PreviewImage.vue'
import useUploadRepositories from '@/repositories/useUploadRepositories'

export default defineComponent({
  components: { UploadFilled, Refresh, View, PreviewImage },
  props: {
    url: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const { warning } = useNotification()

    const dialogVisible = ref<boolean>(false)

    const uploadedFileUrl = ref<string>(props.url)
    const uploadedFile = ref<any>(null)
    const inputFileUrl = ref<string>(props.url)

    const isApplyForAllVariants = ref(true)

    const activeTabName = ref('uploadFile')

    const onUploadDesign = (file: any) => {
      if (file && validateImageFileUploaded(file)) {
        uploadedFile.value = file
        uploadedFileUrl.value = window.URL.createObjectURL(file.raw)
      }
    }

    const onClearUploadedFile = () => {
      uploadedFileUrl.value = ''
      uploadedFile.value = null
    }

    const { onUploadImage } = useUploadRepositories()
    const onSave = async () => {
      if (activeTabName.value === 'uploadFile' && uploadedFile.value) {
        const res = await onUploadImage(uploadedFile.value.raw)
        const uploadedUrl = res?.path
        context.emit('callbackUploadImage', {
          path: uploadedUrl,
          isApplyForAllVariants: isApplyForAllVariants.value,
        })
      } else {
        context.emit('callbackUploadImage', {
          path: inputFileUrl.value,
          isApplyForAllVariants: isApplyForAllVariants.value,
        })
      }
      dialogVisible.value = false
    }

    const previewImageDialog = ref<InstanceType<typeof PreviewImage>>()
    const onClickPreviewImage = () => {
      if (!uploadedFileUrl.value) {
        warning('Please upload image before.')
        return
      }
      previewImageDialog.value?.toggle(uploadedFileUrl.value)
    }

    const toggle = () => {
      dialogVisible.value = !dialogVisible.value
    }

    return {
      onSave,
      toggle,
      uploadedFileUrl,
      uploadedFile,
      validateImageFileUploaded,
      onUploadDesign,
      onClearUploadedFile,
      dialogVisible,
      activeTabName,
      inputFileUrl,
      previewImageDialog,
      onClickPreviewImage,
      isApplyForAllVariants,
    }
  },
})
