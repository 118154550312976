
import { defineComponent } from 'vue'
import { MapHireDesignStatus } from '@/utils/constants'

export default defineComponent({
  components: {},
  props: {
    hireDesignStatus: { type: String },
  },
  setup(props) {
    return { MapHireDesignStatus, props }
  },
})
