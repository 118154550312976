
import { defineComponent, ref } from 'vue'
import { formatTime } from '@/utils/dateTime'
import { CHANGE_ORDER_HISTORY } from '@/utils/orderUtils'

export default defineComponent({
  components: {},
  props: {
    data: {
      type: Object,
      required: true,
    },
    userType: {
      type: String,
      default: 'seller',
    },
  },
  setup(props, {}) {
    const documents = ref()
    const filterDataByUserType = () => {
      let list = []
      if (props.data) {
        const dataSeller = props.data?.seller || []
        const dataAgency = props.data?.agency || []
        const dataUser = props.data?.user || []
        list.push(...dataSeller, ...dataUser, ...dataAgency)
        // if (props.userType !== 'seller') {
        //   list.push(...dataAgency)
        // }
      }
      documents.value =
        list?.sort((a, b) => b.createdDate.localeCompare(a.createdDate)) || []
    }
    filterDataByUserType()
    const { ACTION_NAME } = CHANGE_ORDER_HISTORY()
    const showContentChangeHistory = (row: any) => {
      //
      let content = ''
      const log = row?.log
      if (
        row.actionName === ACTION_NAME.CHANGE_CATALOG_CODE ||
        row.actionName === ACTION_NAME.UPDATE_CATALOG_CODE_BY_FILE
      ) {
        content = `Change catalog code: ${log?.oldCatalogCode} --> ${log?.newCatalogCode}`
      } else if (row.actionName === ACTION_NAME.CHANGE_PREVIEW) {
        content = `Change preview item ${log?.itemIndex}: ${log?.oldPreviewPath} --> ${log?.newPreviewPath}`
      } else if (row.actionName === ACTION_NAME.CHANGE_DESIGN) {
        content = `Change design item ${log?.itemIndex} - ${log?.designName}: ${log?.oldDesignPath} --> ${log?.newDesignPath}`
      } else if (row.actionName === ACTION_NAME.UPLOAD_DESIGN) {
        content = `Upload design item ${log?.itemIndex} - ${log?.designName}: ${log?.newDesignPath}`
      } else if (row.actionName === ACTION_NAME.VERIFY_ADDRESS) {
        content = `Verify address: Verified`
      }
      return content
    }

    return {
      formatTime,
      documents,
      filterDataByUserType,
      showContentChangeHistory,
    }
  },
})
